<!--能源流向页-->
<template>
  <div id="energyFlow" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <!-- 头部标题 -->
    <div class="header">
      <my-PageTitle>{{ $t('energyFlow.a1') }}</my-PageTitle>
    </div>
    <div class="main">
      <!-- 条件查询栏 -->
      <div class="main-queryBar">
        <div class="condition">
           <!-- 显示层级 -->
          <a-select v-model="level" style="width: 120px;margin-right:15px" @change="getEneKindData" :disabled="userType===3 || userType===4">
            <a-select-option :value="1">
              {{ $t('energyFlow.a4') }}
            </a-select-option>
            <a-select-option :value="2">
              {{ $t('energyFlow.a5') }}
            </a-select-option>
            <a-select-option :value="3">
              {{ $t('energyFlow.a6') }}
            </a-select-option>
          </a-select>
          <!-- 碳排放 -->
          <a-select v-model="carbon" style="width: 120px;margin-right:15px" @change="carbonChange" :disabled="carbon!=1 && parseInt(eneKind) !=1">
            <a-select-option :value="0">
              {{ $t('energyFlow.a2') }}
            </a-select-option>
            <a-select-option :value="1">
              {{ $t('energyFlow.a3') }}
            </a-select-option>
          </a-select>
          <!-- 能源类型 -->
          <a-select v-model="eneKind" style="width: 120px;margin-right:15px" v-if="carbon==0">
            <a-select-option v-for="(item,index) in eneKindData" :key="index" :value="item.no">
              {{ item.name }}
            </a-select-option>
          </a-select>
         
          <a-dropdown v-model="visible">
            <a-menu slot="overlay">
              <a-menu-item key="1">
                <!-- 是否显示分组 -->
                <a-checkbox v-model="displayGroup">
                  {{ $t('energyFlow.a8') }}
                </a-checkbox>
              </a-menu-item>
              <a-menu-item key="2">
                <!-- 是否显示设备 -->
                <a-checkbox v-model="displayEquipment" :disabled="!displayGroup">
                  {{ $t('energyFlow.a9') }}
                </a-checkbox>
              </a-menu-item>
              <a-menu-item key="3">
                <!-- 是否显示租户 -->
                <a-checkbox v-model="displayTenant" :disabled="!displayGroup || !displayEquipment">
                  {{ $t('energyFlow.a10') }}
                </a-checkbox>
              </a-menu-item>
            </a-menu>
            <a-button style="margin-right:15px">
              {{ $t('energyFlow.a7') }}
              <a-icon type="down" /> 
            </a-button>
          </a-dropdown>
          <a-select v-model="reportType" style="width:80px;margin-right:15px">
            <a-select-option :value="3">
              {{ $t('energyFlow.a11') }}
            </a-select-option>
            <a-select-option :value="2">
              {{ $t('energyFlow.a12') }}
            </a-select-option>
            <a-select-option :value="1">
              {{ $t('energyFlow.a13') }}
            </a-select-option>
          </a-select>
          <div class="date">
              <!-- 年条件数据值选择 -->
              <transition name="slide-fade">
                  <div class="date-select" v-if="reportType == 3">
                      <a-select v-model="year" style="width:100px;margin-right:15px">
                          <a-select-option v-for="(item,index) in yearData" :key="index" :value="item.value">
                              {{item.name}}
                          </a-select-option>
                      </a-select>
                      <a-checkbox v-model="defineMonth" style="margin-right:7px">
                          {{ $t('energyFlow.a14') }}
                      </a-checkbox>
                      <a-select v-model="month" style="width: 100px" :disabled="!defineMonth">
                          <a-select-option :value="1">
                              {{ $t('energyFlow.a15') }}
                          </a-select-option>
                          <a-select-option :value="2">
                              {{ $t('energyFlow.a16') }}
                          </a-select-option>
                          <a-select-option :value="3">
                              {{ $t('energyFlow.a17') }}
                          </a-select-option>
                          <a-select-option :value="4">
                              {{ $t('energyFlow.a18') }}
                          </a-select-option>
                          <a-select-option :value="5">
                              {{ $t('energyFlow.a19') }}
                          </a-select-option>
                          <a-select-option :value="6">
                              {{ $t('energyFlow.a20') }}
                          </a-select-option>
                          <a-select-option :value="7">
                              {{ $t('energyFlow.a21') }}
                          </a-select-option>
                          <a-select-option :value="8">
                              {{ $t('energyFlow.a22') }}
                          </a-select-option>
                          <a-select-option :value="9">
                              {{ $t('energyFlow.a23') }}
                          </a-select-option>
                          <a-select-option :value="10">
                              {{ $t('energyFlow.a24') }}
                          </a-select-option>
                          <a-select-option :value="11">
                              {{ $t('energyFlow.a25') }}
                          </a-select-option>
                          <a-select-option :value="12">
                              {{ $t('energyFlow.a26') }}
                          </a-select-option>
                      </a-select>
                  </div>
              </transition>
              <!-- 月条件数据值选择 -->
              <transition name="slide-fade">
                  <div class="date-select" v-if="reportType == 2">
                      <a-month-picker v-model="dataTimes" style="width: 120px" placeholder="Select month"/>
                  </div>
              </transition>
              <!-- 日条件数据值选择 -->
              <transition name="slide-fade">
                  <div class="date-select" v-if="reportType == 1">
                      <a-date-picker v-model="dataTimes" style="width: 150px" placeholder="Select month" />
                  </div>
              </transition>
          </div>
        </div>
        <div>
          <a-button type="primary" ghost @click="energyFlowDataInquire">
            {{ $t('energyFlow.a27') }}
          </a-button>
        </div>
      </div>
      <!-- 计量表格 -->
      <div class="main-content">
        <div class="empty" v-if="tableData.length == 0">
          <a-empty>
            <span class="explain">
                {{ $t('energyFlow.a28') }}
            </span>
          </a-empty>
        </div>
        <div class="data-content" v-if="tableData.length !== 0">
            <div class="table-content">
              <div class="table">
                <a-table :columns="column" :data-source="tableData" :pagination="false" size="middle"/>
              </div>
              <div class="slider">
                <!-- <a-icon type="minus-circle" :style="{ fontSize: '15px',color: '#948686','margin-bottom':'5px'}"/>  -->
                <a-slider style="height:200px" vertical :reverse="true" @change="heightOnChange" />
                <!-- <a-icon type="plus-circle" :style="{ fontSize: '15px',color: '#948686','margin-top':'15px'}"/> -->
              </div>
            </div>
          <!-- 能源流向桑基图 -->
          <div class="chart-content">
            <div class="top">
              <div>
                <span>{{title}}</span>
              </div>
              <div class="top-right">
                <!-- <a-icon type="minus-circle" :style="{ fontSize: '15px',color: '#948686','margin-bottom':'5px'}"/>  -->
                <a-slider style="width: 200px" @change="widthOnChange"/>
                <!-- <a-icon type="plus-circle" :style="{ fontSize: '15px',color: '#948686','margin-top':'15px'}"/> -->
              </div>
            </div>
            <div class="bottom">
              <div class="echarts-content" :style="height">
                <div id="EnergyFlowChart" :style="{ width: '100%', height: '100%' }"></div>
              </div>
            </div>

             <a-button class="fullscreen" type="primary" icon="fullscreen" @click="showModal" shape="circle"></a-button>
          </div>
        </div>
      </div>
    </div>
    <my-FlowChart-dialog :option="option"></my-FlowChart-dialog>
  </div>
</template>

<script>
import PageTitle from '../../components/Title/PageTitle.vue'
import Sankey from './components/Sankey.vue'
import { energyFlowData } from '../../api/energy'
import { EleResize } from '../../utils/esresize'
import moment from "moment"
import energyFlowChartDialog from './components/energyFlowChartDialog'
export default {
  components:{
      'my-PageTitle':PageTitle,
      'my-Sankey':Sankey,
      'my-FlowChart-dialog':energyFlowChartDialog
    },
  data(){
    return{
      loading: false,
      carbon: 0,                   // 用电量/碳排放：0:用电量,1:碳排放
      reportType: 3,               // 报表类型：1:日报,2:月报,3:年报
      level: 1,                    // 显示层级：1:客户,2:建筑,3:站点
      displayGroup: true,          // 是否显示分组，默认显示
      displayEquipment: false,     // 是否显示设备
      displayTenant: false,        // 是否显示租户
      dataTimes: moment(),         // 所选择日期列表，用于日报，月报
      year: '2022',                // 所选年份，年报使用
      yearData: [],                // 年份列表
      eneKindData:[],
      eneKind:undefined,
      defineMonth: false,          // 自定义初始月
      month: 1,                    // 自定义月份选择值
      column:[
        {
          title: this.$t('energyFlow.a29'),
          dataIndex: 'name',
          key: 'name',
          align:'left'
        },
        {
          title:()=>{
            if(this.carbon == 0){
              return this.$t('energyFlow.a2')
            }else{
              return this.$t('energyFlow.a3')
            }
          },
          dataIndex: 'value',
          key: 'value',
          align:'right'
        },
        {
          title: this.$t('energy.enedata165'),
          dataIndex: 'unit',
          key: 'unit',
          align:'left'
        },
      ],
      tableData:[],
      visible: false,
      echarts: undefined,
      option: {
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
        },
        animation: false,
        series: {
          type: "sankey",
          top:'80px',
          nodeGap:15,
          nodeAlign:'justify',
          data: [],
          links: [],
        }
      },
      height:{
        height:'100%',
        width: '100%'
      },
      dheight:{
        height:'100%',
        width: '100%',
      },
      title:'',
      svisible: false,
      userType:0,
    }
  },
  // 页面数据初始化
  mounted(){
    this.loading = true;
    this.userType = JSON.parse(sessionStorage.getItem('user')).userType;
    console.log(this.userType);
    if(this.userType===3 || this.userType===4){
        this.level = 3;
    }
    this.getEneKindData()
    const data = {
      action: 0,
      clientId: this.$store.getters.clientId,
      siteId: this.$route.query.id,
      sitegrId: this.$store.getters.sitegrId
    }
    energyFlowData(data)
    .then((res)=>{
      // console.log(res)
      const { data:{ fiscalYear, messageList, yearData} } = res
      this.year = fiscalYear.toString()
      this.yearData = yearData
      this.loading = false
    })
    .catch((err)=>{
      console.log(err)
      this.loading = false
    })

    
  },
  methods:{
    moment,
    carbonChange(){
        this.eneKind = "1";
        if(this.level===0){
            if(this.eneKindData){
                this.eneKind = this.eneKindData[0].no;    
            }
        }
    },
    getEneKindData(){
        const data1 = {
          action: 2,
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.id,
          level: this.level,
          year :this.year
        };
        energyFlowData(data1)
        .then((res)=>{
          console.log(res);
          this.eneKindData = res.data.eneKindData;
          if(this.eneKindData)
            this.eneKind = this.eneKindData[0].no;
        })
        .catch((err)=>{
          console.log(err);
        });
    },
    // 能源流向数据查询
    energyFlowDataInquire(){
      this.loading = true
      const reportType = this.reportType
      const data = {
        action: 1,
        clientId: this.$store.getters.clientId,
        siteId: this.$route.query.id,
        sitegrId: this.$store.getters.sitegrId,
        level: this.level,
        reportType: this.reportType,
        carbon: this.carbon,
        displayGroup: this.displayGroup ? 1 : 0,
        displayEquipment: this.displayEquipment ? 1 : 0,
        displayTenant: this.displayTenant ? 1 : 0,
        eneKind:this.carbon==0?this.eneKind:1
      }
      if( reportType == 1 ){
        const _dataTimes = JSON.stringify(this.dataTimes) 
        const dataTimes = JSON.parse(_dataTimes)
        data.dataTimes = [moment(dataTimes).format('YYYY/MM/DD')]
      }else if( reportType == 2 ){
        const _dataTimes = JSON.stringify(this.dataTimes) 
        const dataTimes = JSON.parse(_dataTimes)
        data.dataTimes = [moment(dataTimes).format('YYYY/MM')]
      }else if ( reportType == 3 ){
        data.defaultStatistic = 1
        data.year = this.year
        data.month = this.month
      }
      // console.log(data)
      energyFlowData(data)
      .then(res=>{
        // console.log(res)
        const { data:{ directionData,siteUnits,tableData,unit}} = res
        this.tableData = tableData
        this.option.series.data = directionData.data
        this.option.series.links = directionData.links
        this.title = directionData.text
        if(this.echarts !== undefined){ this.echarts.dispose() }
        this.$nextTick(()=>{
          this.LoadingChart()
        })
        this.loading = false
      })
      .catch(err=>{
        console.log(err)
        this.loading = false
      })
    },
    // 加载图表
    LoadingChart(){
      const resizeDiv = document.getElementById('EnergyFlowChart')
      let myChart = this.$echarts.init(document.getElementById('EnergyFlowChart'))
      this.echarts = myChart
      myChart.setOption(this.option)
      const listener = () => { myChart.resize() }
      EleResize.on(resizeDiv, listener)
    },
    showModal(){
      this.svisible = true;      
    },
    // 
    heightOnChange(value) {
      if(value == 0 ){
        this.height.height = '100%'
      }else{
        this.height.height = `${100 + 30 * value}%`
      }
    },
    widthOnChange(value){
      if(value == 0 ){
        this.height.width = '100%'
      }else{
        this.height.width = `${100 + 2 * value}%`
      }
    }
  }
}
</script>

<style scoped>
#energyFlow{
  width: 100%;
  height: 100%;
}
.main{
  width: 100%;
  height: calc(100% - 50px);
}
.main-queryBar{
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.condition{
  display: flex;
  align-items: center;
}
.date{
  position: relative;
  height: 32px;
}
.date-select{
  width: 400px;
  position: absolute;
}
.main-content{
  width: 100%;
  height: calc(100% - 72px);
}
.data-content{
  display: flex;
  height: 100%;
}
.table-content{
  display: flex;
  align-items: flex-start;
  width: 350px;
  height: 100%;
  border-radius: 3px;
  padding: 0px 0px 20px 20px;
  overflow: hidden;
}
.table{
  height: 100%;
  border: 1px solid rgba(12,12,12,0.25);
  border-radius: 3px;
  overflow: auto;
}
.slider{
  display: flex;
  flex-direction: column;
  align-items: center;
  height:calc(100% - 20px);
}
.chart-content{
  width: calc(100% - 350px);
  overflow: hidden;
  position: relative;
}
.fullscreen{
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.echarts-content{
  overflow: hidden;
}
.top{
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  margin-right: 20px;
}
.bottom{
  height: calc(100% - 36px);
  overflow: auto;
}
.top-right{
  width: 200px;
  display: flex;
  align-items: center;
}
.empty{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.explain{
  font-size: 20px;
  color: #7682ce;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>