<!--桑基图-->
<template>
  <div id="Sankey">
    <div :id="echartsId" :style="{ width: '100%', height: '100%' }"></div>
  </div>
</template>

<script>
import { EleResize } from '../../../utils/esresize';

export default {
  props: ['echartsId','data'],
  data() {
    return {
      echarts:undefined,
      option: {
        title: {
          text: this.data.text || '',
          subtext: this.data.subtext || '',
          left: "center",
          top: "10px",
        },
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
        },
        animation: false,
        series: {
          type: "sankey",
          top:'50px',
          data: this.data.data || [],
          links: this.data.links || [],
        }
      },
    };
  },
  watch:{
    sankeyData:{
      handler(newValue){
        console.log(newValue)
        if(this.echarts !== undefined){
          this.echarts.dispose()
          this.drawLine()
        }
      }
    }
  },
  mounted() {
    this.drawLine();
  },
  methods: {
    drawLine() {
      let myChart = this.$echarts.init(document.getElementById(this.echartsId));
      this.echarts = myChart
      let resizeDiv = document.getElementById(this.echartsId);
      myChart.setOption(this.option);
      let listener = () => {
        myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },
  },
};
</script>

<style scoped>
#Sankey {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 3px;
  /* box-shadow: 0 0px 10px #bdbcbc; */
}
</style>